// User styles
//
// This is the place where you should place all additional styles and imports.
// This will allow you to take advantage of future updates with lower time consumed on the upgrade process.

.bg-primary, .bg-dark {
  background: rgb(63,13,153) !important;
  background: linear-gradient(171deg, rgba(63,13,153,1) 0%, rgba(81,15,168,1) 35%, rgba(124,15,168,1) 100%) !important;
}

.btn-app-store:hover {
  background: rgba(1,1,1,0.5);
}

.text-purple {
  color: $purple;
}

.fill-purple {
  fill: #5719ab !important;
}

.footer-dark {
  color: rgba(255, 255, 255, 0.8)
}

.footer-dark .nav .nav-item .nav-link, .footer-dark .list-unstyled li a, .footer-dark .footer-link {
  color: rgba(255, 255, 255, 0.8)
}

.w-20 {
  width: 20% !important;
}